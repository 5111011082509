.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
  }
  
  .app__whitebg {
    background-color: var(--white-color);
  }
  
  .app__primarybg {
    background-color: var(--primary-color);
  }

  .app__nansenbg {
    background-color: var(--nansen-bg-color);
  }

  .app__nansendgreenbg {
    background-color: var(--nansen-dgreen-color);
  }
  
  .app__container {
    width: 100%;
    min-height: 100vh;
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--lightGray-color);
      font-weight: 200;
      line-height: 1.2;
      font-size: 0.7rem;

      @media screen and (min-width: 2000px) {
        font-size: 1.1rem;
      }
    
      @media screen and (max-width: 450px) {
        font-size: 0.5rem;
      }
    }
  }
  
  .head-text {
    font-family: var(--font-jost);
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    color: var(--white-color);
    text-transform: capitalize;
    line-height: 4rem;
  
    span {
      color: var(--nansen-green-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

  .head-text-name {
    font-size: 2.9rem;
    font-weight: 400;
    text-align: center;
    color: var(--secondary-color);
    text-transform: capitalize;
  
    span {
      color: var(--secondary-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--lightGray-color);
    line-height: 1.5;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--lightGray-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    a {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255, 0.05);
      margin: 0.25rem 0;
      border: 1px solid var(--darkgray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.2s ease-in-out;
  
      svg {
        width: 16px;
        height: 16px;
        color: var(--lightGray-color);
      }
  
      &:hover {
        border-color: var(--nansen-green-color);
        transform: translate(0, -1px);
  
        svg {
          color: var(--nansen-green-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 45px;
        height: 45px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 22px;
          height: 22px;
          
        }
      }
    }
  }
  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--darkgray-color);
      margin: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--nansen-green-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 12px;
        height: 12px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
  
    .copyright {
      padding: 2rem;
    }
  }
