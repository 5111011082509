@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --font-base: "Poppins", sans-serif;
  --font-jost: "Jost", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #428BC6;
  --nansen-bg-color: #001421;
  --nansen-dgreen-color: #012735;
  --nansen-green-color: #00e3b4;
  
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --darkgray-color: #4d5157;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.flex {
  display: flex;
  align-content: center;
}

.grid {
  display: grid;
  align-content: center;
}

input, textarea {
  font-size: 100%;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

img {
  height: auto;
  width: 100%;
}

.icon {
  font-size: 25px;
  cursor: pointer;
}

.btn {
  border: 2px solid var(--secondary-color);
  padding: 0.5rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
  color: var(--primary-color);
  background: transparent;
}

btn::before {
  content: '';
  height: 100%;
  width: 0%;
  background: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: .2s;
}

btn::after {
  content: '';
  height: 100%;
  width: 0%;
  background: var(--secondary-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: .5s;
}

btn:hover::before, .btn:hover::after {
  width: 100%;
}

.sectionTitle {
  padding: 1rem 0;
  font-size: 2rem;
  color: var(--gray-color);
  display: flex;
  align-content: center;
}

.titleNumber {
  color: var(--secondary-color);
  margin: 0 0.6rem;
  font-weight: 500;
}

.titleText {
  position: absolute;
  width: max-content;
}

.underline {
  position: absolute;
  width: 100%;
  height: 3px;
  top: 35px;
  border-radius: 10px;
  background: var(--gray-color);
  overflow: hidden;
}

.underline span {
  top: 0;
  left: 10px;
  width: 10px;
  border-radius: 10px;
  position: absolute;
  background: var(--lightGray-color);
  animation: moveLine 2s linear infinite;
}

@keyframes moveLine {
  0% {
    left: -20px;
  }

  100% {
    left: 100px;
  }
}