.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;

  h2 {

    @media screen and (max-width: 700px) {
      font-size: 2.2rem;
      line-height: 2.4rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 1.8rem;
      }
    }
  }
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 700px) {
    width: 85%;
  }

  @media screen and (min-width: 2000px) {
    margin-bottom: 6rem;
  }

  h2 {
    font-family: var(--font-jost);
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: 2000px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 700px) {
      font-size: 0.94rem;
      width: 95%;
    }

    @media screen and (min-width: 2000px) {
      font-size: 1.2rem;
    }
  }
  }

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.7rem 1.3rem;
  border-radius: 0.75rem;

  h2 {
    font-family: var(--font-base);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.15rem;
    margin-bottom: 0.4rem;

    @media screen and (max-width: 700px) {
      font-size: 0.94rem;
      margin-bottom: 0.1rem;
      margin-top: 0.1rem;
    }

    @media screen and (min-width: 1400px) {
      font-size: 1.2rem;
    }

    @media screen and (min-width: 2000px) {
      font-size: 1.3rem;
    }
  }

  p {
    width: 90%;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 300;

    @media screen and (max-width: 700px) {
      font-size: 0.87rem;
      margin-bottom: 0.5rem;
      width: 100%;
    }

    @media screen and (min-width: 1400px) {
      font-size: 0.95rem;
    }

    @media screen and (min-width: 2000px) {
      font-size: 1rem;
    }
  }

  img {
    margin-top: 1.5rem;
    width: 35%;
    height: auto;
    border-radius: 15px;
    object-fit: cover;

    @media screen and (max-width: 700px) {
      margin-top: 1.2rem;     
    }
  }

  @media screen and (max-width: 700px) {
    width: 80%;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
  }

  @media screen and (min-width: 1400px) {
    width: 300px;
  }

  @media screen and (min-width: 2000px) {
    width: 300px;
    margin: 2rem 2.5rem;

    img {
      width: 40%;
      height: auto;
      margin-top: 2rem;
    }
  }
}

.app__desc {
  flex: 1;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  p {
    text-align: center;
  }

  h1 {
    text-align: center;
  }

}