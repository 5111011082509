#home {
    position: relative;
    background: linear-gradient(to bottom, var(--nansen-bg-color), var(--nansen-dgreen-color));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: var(--font-jost);
    overflow: hidden;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }

  .homeSection {

    .homeContainer {
      margin-top: 4rem;
      padding: 4rem 1.5rem;
  
      .introText {
        font-size: 1.2rem;
        line-height: 1rem;
        color: var(--lightGray-color);
        font-weight: 400;
        letter-spacing: 0.3px;

        @media screen and (min-width: 2000px) {
          font-size: 2rem;
          line-height: 2rem;
        }
      
        @media screen and (max-width: 700px) {
          font-size: 1rem;
        }
      }

      .title {
        font-size: 5rem;
        color: var(--nansen-green-color);
        font-weight: 500;
        line-height: 5rem;
        padding-top: 0.3rem;
        padding-bottom: 1rem;

        @media screen and (min-width: 2000px) {
          font-size: 7rem;
          line-height: 8rem;
          padding-bottom: 1.7rem;
        }
      
        @media screen and (max-width: 700px) {
          font-size: 4rem;
          line-height: 4rem;
          padding-bottom: 1rem;
        }
      }

      .subTitle {
        display: block;
        font-size: 3.4rem;
        font-weight: 400;
        color: var(--white-color);
        line-height: 2.8rem;
        padding-bottom: 1rem;
        padding-top: 1rem;

        @media screen and (min-width: 2000px) {
          font-size: 5rem;
          line-height: 5rem;
        }
      
        @media screen and (max-width: 700px) {
          font-size: 2.5rem;
          line-height: 2.5rem;
          padding-bottom: 0.5rem;
          padding-top: 0.4rem;
        }
      }

      .homeParagraph {
        font-size: 1.2rem;
        color: var(--lightGray-color);
        padding-top: 0.5rem;
        padding-bottom: 4rem;
        max-width: 600px;
        

        @media screen and (min-width: 2000px) {
          font-size: 2rem;
          line-height: 2.5rem;
          max-width: 1000px;
        }
      
        @media screen and (max-width: 700px) {
          font-size: 1rem;
        }
      }

      .lowerHomeSection {
        .containerBtn {
          padding: .6rem 1rem;
          background: var(--white-color);
          border: none;
          outline: none;
          border-radius: 50px;
          transition: 0.2s ease-in-out;

          &:hover {
            background: var(--secondary-color);

            a {
              color: var(--white-color);
            }
          }

          a {
            font-size: 15px;
            font-weight: 500;
            gap: .5rem;
            align-items: center;
            color: var(--gray-color);
          }
        }
      }
      .scrollDiv {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        color: var(--white-color);

        .icon {
          font-size: 2.5rem;
          color: var(--nansen-green-color);
          animation: move 5s infinite;

          @media screen and (min-width: 2000px) {
            font-size: 2.8rem;
          }
        
          @media screen and (max-width: 700px) {
            font-size: 2rem;
          }
        }

        @keyframes move {
          0% {
            opacity: 1;
            transform: translateY(4px);
          }
          25% {
            opacity: .7;
            transform: translateY(0px);
          }
          50% {
            opacity: 1;
            transform: translateY(4px);
          }
          75% {
            opacity: .7;
            transform: translateY(0px);
          }
          100% {
            opacity: 1;
            transform: translateY(4px);
          }
        }
      }
    }
  
  }








  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
  
    padding: 6rem 2rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
    }
  }
  
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }
  }
  
  .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    .badge-cmp,
    .tag-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      z-index: 1;

      .head-text-name {
        font-weight: 700;
      }
    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 2.2rem;
      margin-right: 1.55rem;
  
      p {
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
      }
    }

    .wave {
      animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
      animation-duration: 2.5s;        /* Change to speed up or slow down */
      animation-iteration-count: infinite;  /* Never stop waving :) */
      transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
      display: inline-block;
    }  
  
    span {
      font-size: 2.5rem;    
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 60%;
        height: 60%;
      }
    }
  
    // circles with different height & width
  
    div:nth-child(1) {
      width: 100px;
      height: 100px;
      z-index: 1;
    }
  
    div:nth-child(2) {
      margin: 1.75rem;
      width: 150px;
      height: 150px;
      z-index: 1;
    }
  
    div:nth-child(3) {
      width: 80px;
      height: 80px;
      z-index: 1;
    }
  
    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: 400px;
        height: 400px;
        z-index: 1;
      }
  
      div:nth-child(3) {
        width: 170px;
        height: 170px;
        z-index: 1;
      }
  
      div:nth-child(1) {
        width: 200px;
        height: 200px;
        z-index: 1;
      }
    }
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
  
      div {
        margin: 1rem;
      }
    }
  }
  
  .app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 85%;
      object-fit: contain;
      z-index: 1;
      margin-right: 30px;      
    }
  
    .overlay_circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 0px 50px rgba(147, 221, 255, 0.4));

    }
  
    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
  }

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}