#projects {
    position: relative;
    background: linear-gradient(to bottom, var(--nansen-dgreen-color), var(--nansen-bg-color), var(--nansen-bg-color), var(--nansen-bg-color), var(--nansen-bg-color), var(--nansen-dgreen-color));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }

.app__works {
    flex: 1;
    width: 80%;
    flex-direction: column;
  }
  
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  
    margin: 4rem 0 2rem;
  
    .app__work-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.6rem;
      background-color: #fff;
      color: #000;
      font-weight: 600;
  
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--nansen-green-color);
        color: #fff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 0.6rem 1.5rem;
        border-radius: 0.85rem;
        font-size: 1.1rem;
      }
    }
  
    .item-active {
      background-color: var(--nansen-green-color);
      color: #fff;
    }
  }
  
  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  
    .app__work-item {
      width: 330px;
      flex-direction: column;
      display: flex;
      align-items: flex-start; /* Start content at the top */
      overflow: hidden; /* Cut off any excess text */
      text-overflow: ellipsis " [..]"; /* Add an ellipsis */
      
      margin: 1rem;
      padding: 1rem;
      border-radius: 5px;
      background-color: var(--nansen-dgreen-color);
  
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
      }
  
      @media screen and (min-width: 2000px) {
        width: 420px;
        padding: 1.2rem;
        border-radius: 0.75rem;
      }
  
      @media screen and (max-width: 500px) {
        width: 90%;
        height: auto;
        margin: 1rem;
      }
    }
  }
  
  .app__work-img {
    width: 100%;
    height: 230px;
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      height: 300px;
    }
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  
    border-radius: 5px;
    opacity: 1;
    transition: all 0.3s ease;
  
    div {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: rgba(238, 238, 238, 0.719);
      color: var(--nansen-bg-color);
      text-overflow: ellipsis " [..]";
      flex-direction: column;
  
      margin: 1rem;
      font-family: var(--font-jost);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      p {
        font-size: 1.1rem;
        color: var(--nansen-dgreen-color);
      }

      svg {
        width: 50%;
        height: 50%;
        color: var(--nansen-dgreen-color);
      }
    }
  }
  
  .app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    text-overflow: ellipsis " [..]";
    

    p {
      color: var(--lightGray-color);

      @media screen and (min-width: 2000px) {
        font-size: 1.2rem;
      }
    }
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
      font-weight: 500;
      font-size: 1.1rem;
      color: var(--white-color);
  
      @media screen and (min-width: 2000px) {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.35rem;
        font-weight: 600;
      }
    }
  
    .app__work-tag {
      position: absolute;
  
      padding: 0.5rem 1rem;
      border-radius: 7px;
      background-color: var(--nansen-dgreen-color);
      top: -25px;
    }
  }