.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 2rem 2rem;
    text-decoration: none;
    text-align: center;


    .app__footer-card1 {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      background-color: var(--gray-color);
      cursor: default;
      transition: all 0.1s ease-in-out;

      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
        cursor: text;
      }

      a {
        text-decoration: none;
        font-weight: 500;

        p {
          font-weight: 500;
          text-decoration: none;
        }

      }

      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }

      @media screen and (max-width: 450px) {
        width: 100%;
      }      
    }
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 5px;
      cursor: pointer;
      background-color: var(--nansen-dgreen-color);
  
      transition: all 0.1s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;

        @media screen and (max-width: 700px) {
          width: 35px;
          height: 35px;
          margin: 0 0.7rem;
        }  

        @media screen and (min-width: 2000px) {
          width: 50px;
          height: 50px;
          margin: 0 1.5rem;
        }  
      }
  
      p {
        font-weight: 500;

        @media screen and (max-width: 600px) {
          font-size: 0.83rem;
        }  

        @media screen and (min-width: 2000px) {
          font-size: 1.4rem;
        }  

      }
      a {
        flex: 100%;
        text-align: center;
        text-decoration: none;
        font-weight: 500;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 80%;
    }

    .app__footer-card-cert {
      background-color: var(--white-color);
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
  
      transition: all 0.1s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
        text-decoration: none;
      }
      a {
        flex: 100%;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }

    a {
      display: flex;
      text-decoration: none;

      p {
        text-align: center;
        flex: 100%;
      }
    }
  
    }
  
  /*
  .app__footer-cards .app__footer-card:last-child {
    background-color: #f2f7fb;
  }
  */
  
  
  .app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
  
    div {
      width: 100%;
  
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--primary-color);
  
      transition: all 0.2s ease-in-out;
  
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: var(--primary-color);
  
        font-family: var(--font-base);
        color: var(--secondary-color);
        outline: none;
      }
  
      textarea {
        height: 170px;
      }
  
      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--secondary-color);
  
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: var(--font-base);
  
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
  
      &:hover {
        background-color: #2430af;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }

  .scrollDiv {
    padding-top: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: var(--white-color);

    p {
      font-size: 0.9rem;
      color: var(--gray-color);
      font-weight: 300;
      text-transform: uppercase;
    }

    .icon {
      font-size: 2.5rem;
      color: var(--gray-color);
      animation: move 5s infinite;

      @media screen and (min-width: 2000px) {
        font-size: 2.8rem;
      }
    
      @media screen and (max-width: 700px) {
        font-size: 2rem;
      }
    }

    @keyframes move {
      0% {
        opacity: .4;
        transform: translateY(3px);
      }
      25% {
        opacity: .6;
        transform: translateY(0px);
      }
      50% {
        opacity: .4;
        transform: translateY(3px);
      }
      75% {
        opacity: .6;
        transform: translateY(0px);
      }
      100% {
        opacity: .4;
        transform: translateY(3px);
      }
    }
  }