.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;
    background: var(--nansen-dgreen-color), 0.18;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px, solid var(--nansen-dgreen-color), 0.18;
    position: fixed;
    z-index: 2;
}

.app__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
        font-family: var(--font-jost);
        font-weight: 500;
        font-size: 1.5rem;
        color: var(--nansen-green-color);

        @media screen and (min-width: 2000px) {
            font-size: 1.8rem;
          }
        
        @media screen and (max-width: 700px) {
            font-size: 1.2rem;
          }
    }
    
        @media screen and (min-width: 2000px) {
            width: 180px;
            height: 40px;  
        }
    }


.app__navbar-links {
    flex:  1;
    display: flex;
    justify-content: right;
    align-items: center;
    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;
        position: relative left 50px;
        font-size: 1.2rem;
        flex-direction: row;

        &:hover {
            div {
                background: var(--nansen-green-color);
            }
        }

        div {
            width: 6px;
            height: 6px;
            background-color: transparent;
            border-radius: 50%;
            margin-right: 5px;
        }

        a {
            font-family: var(--font-jost);
            color: var(--white-color);
            text-decoration: none;
            flex-direction: row;
            text-transform: capitalize;
            font-weight: 400;
            transform: all 0.2s ease-in-out;

            @media screen and (min-width: 2000px) {
                font-size: 1.5rem;
              }
            
            @media screen and (max-width: 700px) {
                font-size: 0.9rem;
              }

            &:hover {
               color: var(--nansen-green-color); 
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--darkgray-color);

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);        
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 40%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: var(--nansen-dgreen-color);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);

        svg {
            width: 35px;
            height: 35px;
            color: var(--white-color); 
            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
               margin: 1rem;
               
               a {
                color: var(--white-color);
                text-decoration: none;
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: 400;
                font-family: var(--font-jost);

                &:hover {
                    color: var(--nansen-green-color); 
                 }
               }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;            
        }
    }

    @media screen and (min-width: 900px) {
        display: none;            
    }
}