#skills {
    position: relative;
    background: linear-gradient(to bottom, var(--nansen-dgreen-color), var(--nansen-dgreen-color), var(--nansen-dgreen-color), var(--nansen-dgreen-color), var(--nansen-dgreen-color), var(--nansen-bg-color));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

}
.app__skills {
    flex: 1;
    width: 95%;
    flex-direction: column;
  }
  
  .app__skills-container {
    width: 80%;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
  
    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
    }
  }
  
  .app__skills-list {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /*3 columns with equal width */
    justify-items: center; /* align items horizontally to the center */
    align-items: center; /* align items vertically to the center */
    grid-row-gap: 1.5rem;
    grid-column-gap: 0rem;
    margin-right: 5rem;
  
    @media screen and (max-width: 1000px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }

  }
  
  .app__skills-item {
    grid-column-end: span 1; /* make all items take up 1 column */
    flex-direction: column;
    text-align: center;

    div {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: var(--nansen-bg-color);      
  
      img {
        width: 60%;
        height: 60%;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
  
      &:hover {
        box-shadow: 0 0 15px var(--nansen-bg-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 95px;
        height: 95px;
      }
  
      @media screen and (max-width: 450px) {
        width: 70px;
        height: 70px;
      }
    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
      font-size: 0.78rem;
      color: var(--lightGray-color);
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
        font-size: 1.1rem;
      }
    }
  }
  
  .app__skills-exp {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    @media screen and (max-width: 1000px) {
      margin-top: 2rem;
    }
  }
  
  .app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .app__skills-exp-works {
    flex: 1;
  
    .app__skills-exp-work {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0.8rem;
  
      h4 {
        font-weight: 500;
        font-size: 1rem;

        @media screen and (min-width: 2000px) {
          font-weight: 500;
          font-size: 1.4rem;
        }
    
        @media screen and (max-width: 450px) {
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
  
      .p-text {
        font-weight: 400;
        color: var(--lightGray-color);
        margin-top: 2px;
        font-size: 0.9rem;

        @media screen and (min-width: 2000px) {
          font-weight: 400;
          font-size: 1.1rem;
        }
    
        @media screen and (max-width: 450px) {
          font-weight: 400;
          font-size: 0.8rem;
        }
      }

      .p-text-company {
        font-size: 0.95rem;
        font-weight: 600;
        color: var(--gray-color);
        margin-top: 2px;
        margin-bottom: 0.4rem;

        @media screen and (min-width: 2000px) {
          font-weight: 400;
          font-size: 1.3rem;
        }
    
        @media screen and (max-width: 450px) {
          font-weight: 400;
          font-size: 0.9rem;
        }
      }
    }
  }
  
  .app__skills-exp-year {
    margin-right: 2rem;
  
    p {
      font-weight: 700;
      font-size: 1.1rem;
      color: var(--nansen-green-color);

      @media screen and (max-width: 450px) {
        font-size: 0.9rem;
      }
  
      @media screen and (min-width: 2000px) {
        font-size: 1.4rem;
      }
    }
  
    @media screen and (max-width: 450px) {
      margin-right: 1rem;
    }

    @media screen and (min-width: 2000px) {
      margin-right: 2rem;
    }
  }
  
  .skills-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }

